import { WorkflowFolder } from "@/app/db/schema";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Folder, EllipsisVertical, Pencil, Trash2 } from "lucide-react";
import { GeistSans } from "geist/font/sans";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface FolderItemProps {
  folder: WorkflowFolder;
  onFolderClick: (folderId: number) => void;
  onEditClick: (folder: WorkflowFolder) => void;
  onDeleteClick: (folder: WorkflowFolder) => void;
}

export function FolderItem({ folder, onFolderClick, onEditClick, onDeleteClick }: FolderItemProps) {
  return (
    <Card className="px-4 shadow-none hover:bg-accent transition-colors group rounded-lg font-space-grotesk">
      <div className="flex items-center justify-between">
        <div 
          className="flex items-center space-x-4 cursor-pointer py-4 w-full"
          onClick={() => onFolderClick(folder.id)}
        >
          <Folder className="h-4 w-4" />
          <span className={`font-semibold font-space-grotesk`}>
            {folder.name}
          </span>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="opacity-0 group-hover:opacity-100">
              <EllipsisVertical className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => onEditClick(folder)}>
              <Pencil className="h-4 w-4 mr-2" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-destructive"
              onClick={() => onDeleteClick(folder)}
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </Card>
  );
} 