"use client";

import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import { VectorStore, Role } from "@/app/db/schema";
import { getRoles, getStorePermissions } from "@/app/actions/data-actions";
import { Separator } from "@/components/ui/separator";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { FileUpload } from "@/components/file-upload";
import { DataDisplay, type DataDisplayRef } from "@/components/data-display";
import { Trash2Icon, Database, ChartNetwork } from "lucide-react";

interface StoreFormData {
  id: number;
  name: string;
  description: string;
  default_selected: boolean;
  roleIds: number[];
}

interface DataModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (data: StoreFormData) => Promise<void>;
  store?: VectorStore;
  isProcessing: boolean;
  onDelete?: (vectorStoreId: number) => Promise<void>;
}

export function DataModal({
  isOpen,
  onOpenChange,
  onSubmit,
  store,
  isProcessing,
  onDelete,
}: DataModalProps) {
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = React.useState<number[]>([]);
  const [files, setFiles] = React.useState<File[]>([]);
  const [formData, setFormData] = React.useState<StoreFormData>({
    id: store?.id ?? 0,
    name: store?.name ?? "",
    description: store?.description ?? "",
    default_selected: store?.default_selected ?? false,
    roleIds: [],
  });

  const dataDisplayRef = React.useRef<DataDisplayRef>(null);

  React.useEffect(() => {
    if (store) {
      setFormData({
        id: store.id,
        name: store.name,
        description: store.description ?? "",
        default_selected: store.default_selected,
        roleIds: selectedRoles,
      });
    }
  }, [store]);

  React.useEffect(() => {
    getRoles().then(setRoles).catch(console.error);
  }, []);

  React.useEffect(() => {
    if (store?.id) {
      getStorePermissions(store.id)
        .then((roleIds) => {
          setSelectedRoles(roleIds);
          setFormData((prev) => ({ ...prev, roleIds }));
        })
        .catch(console.error);
    }
  }, [store?.id]);

  React.useEffect(() => {
    if (!isOpen) {
      setFiles([]);
    }
  }, [isOpen]);

  const toggleRole = (roleId: number) => {
    const newSelectedRoles = selectedRoles.includes(roleId)
      ? selectedRoles.filter((id) => id !== roleId)
      : [...selectedRoles, roleId];

    setSelectedRoles(newSelectedRoles);
    setFormData((prev) => ({ ...prev, roleIds: newSelectedRoles }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(formData);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(file => {
      if (!files.some(existingFile => existingFile.name === file.name)) {
        setFiles(prev => [...prev, file]);
      }
    });
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleUploadComplete = (filename: string) => {
    setFiles((prev) => prev.filter((file) => file.name !== filename));
    dataDisplayRef.current?.refresh();
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this store? This action cannot be undone.')) {
      await onDelete?.(store?.id || 0);
    }
  };

  if (!store?.id) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-6xl">
        <DialogHeader>

          <DialogTitle className="flex items-center gap-2">
            {store.type === "graph_store" ? (
              <ChartNetwork size={16} />
            ) : (
              <Database size={16} />
            )}
            Edit Store
          </DialogTitle>
        </DialogHeader>
        <div className="flex gap-8">
          <form onSubmit={handleSubmit} className="flex flex-col justify-between gap-4  w-1/2 font-space-grotesk">
            <div className="space-y-8">
                <div className="space-y-2">
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="description">Description</Label>
                  <Textarea
                    id="description"
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="flex items-center space-x-4 ">
                  <Switch
                    id="default_selected"
                    checked={formData.default_selected}
                    onCheckedChange={(checked) =>
                      setFormData({ ...formData, default_selected: checked })
                    }
                  />
                  <Label htmlFor="default_selected">Default Selected</Label>
                </div>
                <Separator className="my-4"/>
                <div className="space-y-4">
                  <Label>Permissions</Label>
                  <div className="grid grid-cols-2 gap-4">
                    {roles.map((role) => (
                      <div
                        key={role.id}
                        className="flex items-center gap-4 justify-start"
                      >
                        <Switch
                          checked={selectedRoles.includes(role.id)}
                          onCheckedChange={() => toggleRole(role.id)}
                        />
                        <div className="text-sm font-medium capitalize">
                          {role.name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
            </div>
            <div className="w-full flex justify-end justify-self-end gap-2">
              <Button
                type="button"
                variant="destructive"
                onClick={handleDelete}
                disabled={isProcessing}
              >
                Delete Store
              </Button>
              <Button type="submit" disabled={isProcessing}>
                {isProcessing ? "Processing..." : "Update Store"}
              </Button>
            </div>
          </form>
          <Separator orientation="vertical" />
          <div className="w-1/2">
            <div className="text-sm font-medium mx-2">Data</div>
            <div
              {...getRootProps({
                className:
                  "dropzone font-space-grotesk border-2 border-dashed rounded-md py-8 text-center text-sm mt-4 mb-4" +
                  (isDragActive ? " bg-muted" : ""),
              })}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <div>
              {files.map((file) => (
                <FileUpload
                  key={`${file.name}-${file.lastModified}`}
                  file={file}
                  vectorStoreId={store.id.toString()}
                  onComplete={(filename) => {
                    handleUploadComplete(filename);
                    setFiles(prev => prev.filter(f => f.name !== filename));
                  }}
                />
              ))}
            </div>
            <DataDisplay 
              ref={dataDisplayRef} 
              storeId={store.id} 
              onRefreshRequest={() => dataDisplayRef.current?.refresh()}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
