import { WorkflowFolder } from "@/app/db/schema";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";

interface BreadcrumbsProps {
  breadcrumbs: WorkflowFolder[];
  onBreadcrumbClick: (folderId: number | null) => void;
}

export function Breadcrumbs({ breadcrumbs, onBreadcrumbClick }: BreadcrumbsProps) {
  return (
    <div className="flex items-center space-x-2 mb-4 text-xs font-space-grotesk">
      <Button
        variant="ghost"
        onClick={() => onBreadcrumbClick(null)}
        className="p-1 hover:bg-transparent hover:underline"
      >
        Workflows
      </Button>
      {breadcrumbs.map((folder) => (
        <div key={folder.id} className="flex items-center">
          <ChevronRight className="h-4 w-4 mx-1 text-muted-foreground" />
          <Button
            variant="ghost"
            onClick={() => onBreadcrumbClick(folder.id)}
            className="p-1 hover:bg-transparent hover:underline"
          >
            {folder.name}
          </Button>
        </div>
      ))}
    </div>
  );
} 