"use client";

import * as React from "react";
import { getTools } from "@/app/actions/tools-actions";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ChevronsUpDown, Hammer } from "lucide-react";
import { useToolStore } from "@/app/store/toolStore";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";

export function ToolSelect() {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const { tools, setTools, selectedTools, toggleTool, isToolSelected } =
    useToolStore();

  React.useEffect(() => {
    getTools()
      .then((fetchedTools) => {
        setTools(fetchedTools);
      })
      .catch(console.error);
  }, [setTools]);

  const filteredTools = tools.filter((tool) =>
    tool.name.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className=" justify-between"
          size="sm"
        >
          <div className="flex w-full items-center  gap-2">
            <Hammer size={14} />
            <div className="flex items-center gap-2 overflow-visible relative">
              Tools
              <Badge className="rounded-full text-xs  h-4 w-4 p-0 flex items-center justify-center z-40">
                {selectedTools.length}
              </Badge>
            </div>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[600px] p-4">
        <div className="space-y-4">
          <Input
            placeholder="Search tools..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="mb-4 font-space-grotesk"
          />
          <div className="grid grid-cols-2 gap-4">
            {filteredTools.map((tool) => (
              <label
                key={tool.id}
                className="flex  flex-col rounded-lg border p-4 hover:bg-muted/50 transition-colors"
              >
                <div className="flex items-center justify-between w-full">
                  <div className="space-y-0.5">
                    <div className="text-sm font-medium font-space-grotesk">{tool.name}</div>
                  </div>
                  <Switch
                    checked={isToolSelected(tool.id)}
                    onCheckedChange={() => toggleTool(tool)}
                  />
                </div>
                <div className="text-xs text-muted-foreground mt-2">
                  {tool.description}
                </div>
              </label>
            ))}
          </div>
          {filteredTools.length === 0 && (
            <div className="text-center text-sm text-muted-foreground">
              No tools found
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
